<template>
  <section>
    <breadcrumb :title="$t('accounting')" :subtitle="$t('sales')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
      >

        <b-table-column field="data" :label="$t('date')" v-slot="props">
            <span class="col date">
              {{ formatYYYYMMDD(props.row.date) }}
            </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('count_remittances')" v-slot="props">
            <span class="col count">
              {{ props.row.remittancesTotal }}
            </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('import_pay')" v-slot="props">
            <span class="col common">
              {{ props.row.amountPay }}
            </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('gain_agency')" v-slot="props">
            <span class="col common">
              {{ props.row.earnings }}
            </span>
        </b-table-column>
        <b-table-column field="code" :label="$t('commission_recharge_me')" sortable v-slot="props">
       <span class="col common">
         {{ props.row.commission }} USD
       </span>
        </b-table-column>
      </b-table>
      <paginate :count="desserts.totalRecords"
                :per_page="desserts.itemsPerPage"
                :current-page="desserts.currentPage"
                :total-pages="desserts.totalPages"
                @first="getLast(true)"
                @previous="getSales(desserts.currentPage - 1,desserts.itemsPerPage)"
                @next="getSales(desserts.currentPage + 1,desserts.itemsPerPage)"
                @last="getLast"
                @perPage="getSales(desserts.currentPage,$event)"
                @search="getSales($event,desserts.itemsPerPage)"
      />
      <hr class="hr-divider">
      <div class="columns">
        <div class="column">
          <span class="total-balance">{{ $t('total_summary') }}:</span>
          <span class="total-balance ml-10">{{ desserts.totalRemittances }} {{$t('remittances')}}</span>
        </div>
        <div class="column">
          <span class="total-balance">{{ $t('total_import') }}:</span>
          <span class="total-balance ml-10">{{ desserts.totalAmountPay }} USD</span>
        </div>
        <div class="column">
          <span class="total-balance">{{ $t('gain_agency') }}:</span>
          <span class="total-balance ml-10">{{ desserts.totalEarnings }} USD</span>
        </div>
        <div class="column is-flex">
          <span class="total-balance">{{ $t('commission') }}:</span>
          <div class="is-flex is-flex-direction-column">
            <span class="total-balance ml-10">{{ desserts.totalCommission }} USD</span>
<!--            <span class="total-balance ml-2">{{ 60 }}</span>-->
          </div>
        </div>
      </div>
    </section>
  </section>

</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";

export default {
  name: "SalesComponent",
  components: {Paginate, Breadcrumb},
  data() {
    return {
      totalBalanceUsd: null,
      totalBalanceCup: null,
      propsModals: {},
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
      desserts: [],

    }
  },
  methods: {
    ...mapActions({
      fetchSales: 'FETCH_SALES'
    }),
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    getSales(current = 1, record = 10, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      this.fetchSales(data)
          .then(value => {
            this.desserts = value
          })
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getAgents(page, this.desserts.itemsPerPage)
    },
  },
  created() {
    this.getSales()
  }
}
</script>

<style scoped lang="scss">
.hr-divider {
  height: 1px;
  background: #AEAEAE;
}

.col {
  font-weight: 900;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;

  &.date {
    font-size: 20px;
    color: #C1C1C1;
  }

  &.count {
    font-size: 16px;
    color: #666BAA;
  }

  &.common {
    color: #0B2B46;
    font-size: 16px;
  }

}

.total-balance {
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}
</style>